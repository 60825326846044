import * as PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';

import { capitalize } from '../helpers/utils';

import CountryDetailsCanada from '../components/countryDetailsCanada';
import OtherDestinations from '../components/OtherDestinations/OtherDestinations';
import Sidebar from '../components/Sidebar/sidebar';
import FlightCardsList from '../components/FlightCards/FlightCardsList';
import CallUs from '../components/CallUs/CallUs';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import Layout from '../components/layout';
import Trustpilot from '../components/Trustpilot';
import SubscribeForm from '../components/SubscribeForm';
import SEO from '../components/seo';

import { CURRENCY } from '../constants/currency';
import { isFlykart } from '../helpers/urlParams';
import configurePageData from '../helpers/configurePageData';

class CountryTemplate extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      countryCanadaJson: PropTypes.object.isRequired,
      allMarkdownRemark: PropTypes.object,
      aImage: PropTypes.object,
      fImage: PropTypes.object
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber,
      configuredData: null
    };
  }

  async componentDidMount() {
    const dataConfig = await configurePageData(
      this.props.data.countryCanadaJson.slug
    );
    if (dataConfig) {
      this.setState({
        configuredData: dataConfig
      });
    }
  }

  render() {
    const {
      countryCanadaJson: countryCanadaJSON,
      allMarkdownRemark
    } = this.props.data;

    const countryCanadaJson = {
      ...countryCanadaJSON,
      ...(this.state.configuredData && this.state.configuredData)
    };

    const { phoneNumber } = this.state;
    const county = capitalize(countryCanadaJson.title);
    const path = [
      ...countryCanadaJson.path,
      { name: county, slug: countryCanadaJson.slug }
    ];
    const destination = countryCanadaJson.pairs[0]
      ? capitalize(countryCanadaJson.pairs[0].destination)
      : '';
    const slug = countryCanadaJson.slug;
    const title = countryCanadaJson.pairs[0]
      ? `Flights to ${county} (${countryCanadaJson.pairs[0].destinationIATA}), ${destination} from $${countryCanadaJson.pairs[0].price}`
      : `Find Cheap Flights to ${capitalize(county)}`;
    const description = countryCanadaJson.pairs[0]
      ? `Find cheap flights to country from ${CURRENCY.CANADA}${countryCanadaJson.pairs[0].price}.
     Book online at travelopod.com or call us 24/7 for live booking assistance and last minute travel deals.`
      : `Find Cheap Flights to ${capitalize(
          county
        )}. Book online at travelopod.com or call us 24/7 for live booking assistance and last minute travel deals.`;

    const to = capitalize(countryCanadaJson.title);
    const layoutTitle = `Find Cheap Flights to ${to}`;
    const flightCardsListTitle = `Cheap flights to ${to} from ${CURRENCY.CANADA}${countryCanadaJson.pairs[0].price}`;
    const flykartTheme = isFlykart();
    const img = flykartTheme
      ? this.props.data.fImage.childImageSharp.fluid
      : this.props.data.aImage.childImageSharp.fluid;
    return (
      <Layout
        pageData={countryCanadaJson}
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
        title={layoutTitle}
        image={img}
      >
        <SEO title={title} description={description} slug={slug} />
        <div className="main">
          <Breadcrumbs path={path} />
          <div className="page-content">
            <div className="flight-content">
              <FlightCardsList
                city={countryCanadaJson}
                title={flightCardsListTitle}
                cheapestPrice={
                  countryCanadaJson.pairs[0]
                    ? countryCanadaJson.pairs[0].price
                    : 0
                }
              />
              <CallUs phoneNumber={phoneNumber} />
              <CountryDetailsCanada
                country={countryCanadaJson}
                htmlContent={
                  allMarkdownRemark.edges[0]
                    ? allMarkdownRemark.edges[0].node.html
                    : null
                }
              />
              {countryCanadaJson.nearest && (
                <OtherDestinations
                  data={countryCanadaJson.nearest}
                  text={`Flights to nearby countries on the ${capitalize(
                    countryCanadaJson.title
                  )}`}
                />
              )}
            </div>
            <Sidebar destination={capitalize(countryCanadaJson.title)} />
          </div>
          <SubscribeForm isInnerPage />
        </div>
        <Trustpilot />
      </Layout>
    );
  }
}

export default CountryTemplate;

export const pageQuery = graphql`
  query($title: String!, $image: String) {
    countryCanadaJson(title: { eq: $title }) {
      ...Country_Canada_details
    }

    allMarkdownRemark(filter: { frontmatter: { page: { eq: $title } } }) {
      edges {
        node {
          html
          frontmatter {
            title
            date
          }
        }
      }
    }
    aImage: file(relativePath: { in: [$image, "flight-background.jpg"] }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    fImage: file(relativePath: { in: [$image, "flykart-img/flykart-bg.jpg"] }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
