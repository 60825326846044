import React from 'react';

import { graphql } from 'gatsby';

export default function CountryDetailsCanada(props) {
  return (
    <div>
      {props.htmlContent && (
        <div dangerouslySetInnerHTML={{ __html: props.htmlContent }} />
      )}
    </div>
  );
}

export const countryDetailFragment = graphql`
  fragment Country_Canada_details on CountryCanadaJson {
    title
    nearest {
      slug
      title
    }
    path {
      name
      slug
    }
    slug
    pairs {
      slug
      title
      tags
      isSearchable
      appearanceOnDestination
      published
      thumbnail
      origin
      destination
      startDate
      endDate
      originIATA
      destinationIATA
      price

      airlineLogo
    }
  }
`;

// airlineCode
// airlineName
